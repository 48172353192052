
import { CredentialsDTO } from '@/data/dto/credentials.dto';

interface QueryParams {[key: string]: string; }

export interface IAuthService {
  generateAuthRedirection(): URL;
  checkSigninRedirectCallback(url: URL): Promise<CredentialsDTO>;
}

export default class AuthService implements IAuthService {  
  private readonly host: string;
  private readonly clientID: string;
  private readonly redirectURI: string

  constructor() {
    this.host = process.env.VUE_APP_OPEN_CONNECT_API_HOST;
    this.clientID = process.env.VUE_APP_OPEN_CONNECT_API_CLIENT_ID;
    this.redirectURI = process.env.VUE_APP_OPEN_CONNECT_API_CALLBACK_URI;
  }

  private parseQueryString(query: string): QueryParams {    
    const queryObj: QueryParams = {};
    
    if (query === '') return queryObj;

    const segments = query.split('&').map(s => s.split('='));
    segments.forEach(s => (queryObj[s[0]] = s[1]));
    return queryObj;    
  }

  generateAuthRedirection(): URL {
    const url = new URL(this.host);
    url.searchParams.set('scope', 'openid');
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('client_id', this.clientID);
    url.searchParams.set('redirect_uri', this.redirectURI);
    url.searchParams.set('scope', 'openid profile email');

    return url;
  }

  checkSigninRedirectCallback(url: URL): Promise<CredentialsDTO> {
    return new Promise((resolve, reject) => {

      const query = this.parseQueryString(url.search.substring(1));
      const requiredProps = ['session_state', 'code'];

      if (requiredProps.reduce((i, prop) => i && Object.prototype.hasOwnProperty.call(query, prop), true)) { 
        return resolve({
          code: query.code,
        });
      }

      const errorProps = ['error'];

      if (errorProps.reduce((i, prop) => i && Object.prototype.hasOwnProperty.call(query, prop), true)) {
        return reject(query.error);
      }
      
      return reject({ name: 'UndetectedRedirection'});
      
    });
  }
}
