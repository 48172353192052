
import { Component, Vue } from 'vue-property-decorator';
import AuthService, { IAuthService } from '@/services/auth.service';
import { CredentialsDTO } from '@/data/dto/credentials.dto';
import RouteTitle from '@/components/RouteTitle.vue';

@Component({
  components: {
    RouteTitle,
  },
})
export default class Login extends Vue {

  private authService:IAuthService = new AuthService();
  private isErrorAuthUser = false;

  redirectToLoginPage(): void {
    window.location.href = this.authService.generateAuthRedirection().toString();
  }

  async validateUser(callbackParams: CredentialsDTO): Promise<void> {
    try {
      await this.$store.dispatch('login', callbackParams);
      this.$router.push({ name: 'home', query: {} });
    } catch(e) {
      return;
    }
  }

  async mounted(): Promise<void> {
    const currentUrl = new URL(window.location.href);
    this.authService.checkSigninRedirectCallback(currentUrl)
      .then((cred: CredentialsDTO ) => {
        this.validateUser(cred);
      })
      .catch((err) => {
        if (err.name === 'UndetectedRedirection') {
          setTimeout(() => {
            this.redirectToLoginPage();
          },1000);
        } else {
          this.isErrorAuthUser = true;
        }
      });
  }
}
